import React, {useEffect} from "react";
import "./PortfolioOverview.css";
import {numberFormat} from "../shared";
import {Spinner} from "../shared/components/Spinner";
import {gql, useQuery} from "@apollo/client";
import {StockPortionPieChart} from "../shared/components/StockPortionPieChart";
import {v4 as uuid} from "uuid";

const PORTFOLIO = gql`
    query portfolio {
        portfolio {
            cash
            dividendCollected
            taxesPayed
            stocksValue
            amountInvested
            timestamp
            investments {isin name price numberOfShares buyInTotal buyInPerShare transactions{costs}}
        }
        latestCommandExecution(name: "freezePortfolioValue"){
            executedAt
        }
    }
`;


export const PortfolioOverview = () => {

    const [changeAbsolute, setChangeAbsolute] = React.useState<number>(0);
    const [changeInPercentage, setChangeInPercentage] = React.useState<number>(0);
    const [lastPriceUpdate, setLastPriceUpdate] = React.useState<string>("");
    const [keyIdToTriggerAnimation, setKeyIdToTriggerAnimation] = React.useState<string>(uuid());

    const {error, data} = useQuery(PORTFOLIO, {pollInterval: 60000});

    useEffect(() => {
        const calculateReturn = (currentValue: number, amountInvested: number) => {
            if (!currentValue || !amountInvested) return 0;
            return (currentValue - amountInvested) / amountInvested;
        }

        if (data) {
            setLastPriceUpdate(new Date(data.latestCommandExecution.executedAt).toTimeString().substr(0, 8));
            setChangeInPercentage(calculateReturn(data.portfolio.stocksValue, data.portfolio.amountInvested));
            setChangeAbsolute(data.portfolio.stocksValue - data.portfolio.amountInvested);
            setKeyIdToTriggerAnimation(uuid());
        }
    }, [data]);

    if (error) {
        console.log(error);
        return <p>Error when querying portfolio overview data :(</p>;
    }

    return (
        <React.Fragment>
            <Spinner isLoading={data === undefined}>
                <div key={keyIdToTriggerAnimation} className="Widget greenBackgroundFadeOut portfolioValues">
                    <div>Value of stocks:</div>
                    <div>{numberFormat.format(data?.portfolio.stocksValue!)}</div>
                    <div>Cash:</div>
                    <div>{numberFormat.format(data?.portfolio.cash!)}</div>
                    <div className="greenLarge ">Total:</div>
                    <div
                        className="greenLarge  topBorder">{numberFormat.format(data?.portfolio.cash! + data?.portfolio.stocksValue!)}</div>
                    <div className={changeAbsolute > 0 ? " changePositive" : "changeNegative"}>change:</div>
                    <div className={changeAbsolute > 0 ? " changePositive" : "changeNegative"}>
                        {changeAbsolute > 0 ? "↗" : "↘"} {numberFormat.format(changeAbsolute)}
                        / {(changeInPercentage * 100).toFixed(2)} %
                    </div>
                    <div className=" topBuffer">Dividend collected:</div>
                    <div className=" topBuffer">{numberFormat.format(data?.portfolio.dividendCollected!)}</div>
                    <div>Taxes payed:</div>
                    <div>{numberFormat.format(data?.portfolio.taxesPayed!)}</div>
                    <div className=" AmountInvested">invested:</div>
                    <div>{numberFormat.format(data?.portfolio.amountInvested!)}</div>
                    <div>last update:</div>
                    <div>{lastPriceUpdate}</div>
                </div>
            </Spinner>
            <Spinner isLoading={data === undefined}>
                <StockPortionPieChart portfolio={data?.portfolio}/>
            </Spinner>
        </React.Fragment>
    );
}
