import React, {useEffect} from "react";
import {Chart} from "react-google-charts";
import "./PortfolioHistoryCharts.css";
import {GraphQLResponse, PortfolioHistory as PortfolioHistoryDO} from "../shared/Domain";
import {gql, useQuery} from "@apollo/client";
import {chartOptions} from "../shared";
import {Spinner} from "../shared/components/Spinner";

const PORTFOLIO = gql`
    query portfolio ($period: Period) {
        portfolioHistory(period: $period) {
            amountInvested
            changeInPercentage
            date
            portfolioValue
            cash
        }
    }
`;

export const PortfolioHistoryCharts = () => {

    const {data: data24h} = useQuery(PORTFOLIO, {variables: {period: "LAST_24H"}, pollInterval: 60000});
    const {data: data7d} = useQuery(PORTFOLIO, {variables: {period: "LAST_WEEK"}});

    const [last24hoursData, setLast24hoursData] = React.useState(new Array<Array<any>>());
    const [last7daysData, setLast7daysData] = React.useState(new Array<Array<any>>());

    useEffect(() => {
        const mapToChartData = (portfolioData: GraphQLResponse) => {
            const portfolioHistoryEntries: [PortfolioHistoryDO] = portfolioData.portfolioHistory;
            const mappedData: Array<Array<any>> = portfolioHistoryEntries.map((ph: PortfolioHistoryDO) => {
                return[new Date(ph.date), ph.portfolioValue + ph.cash]
            });
            return mappedData;
        };

        if (data24h) {
            setLast24hoursData([["0", "Value"]].concat(mapToChartData(data24h)));
        }

        if (data7d) {
            setLast7daysData([["0", "Value"]].concat(mapToChartData(data7d)));
        }
    }, [data24h, data7d]);

    return (
        <React.Fragment>
            <Spinner isLoading={last24hoursData.length === 0}>
                <div className="Widget Chart">
                    <Chart
                        chartType="LineChart"
                        data={last24hoursData}
                        width="100%"
                        height="400px"
                        loader={<div>Loading Chart</div>}
                        options={{
                            title: "last 24 hours",
                            ...chartOptions,
                            hAxis: {
                                textStyle: {
                                    color: 'white',
                                },
                                gridlines: {
                                    color: 'transparent',
                                },
                                format: 'HH:mm',
                            }
                        }}
                    />
                </div>
            </Spinner>
            <Spinner isLoading={last7daysData.length === 0}>
                <div className="Widget Chart">
                    <Chart
                        chartType="LineChart"
                        data={last7daysData}
                        loader={<div>Loading Chart</div>}
                        width="100%"
                        height="400px"
                        options={{
                            title: "last 7 days",
                            ...chartOptions,
                        }}
                    />
                </div>
            </Spinner>
        </React.Fragment>
    );
}
