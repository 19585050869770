import React from "react";
import {Account} from "./Account";
import {useQuery, gql} from "@apollo/client";


const QUERY_ACCOUNTS = gql`
    query accounts{
        accounts {
            name
        }
    }
`;

export function Accounts() {

    const [accountName, setAccountName] = React.useState("ING DIBA Stefan");

    const {data, loading, error} = useQuery(QUERY_ACCOUNTS, {pollInterval: 30000});

    return <div>
        <h1>Accounts</h1>
        {error && <p>Error :(</p>}
        {loading && <p>Loading...</p>}
        {data && data.accounts.map((a: { name: string }) => <button key={a.name}
                                                                    onClick={() => setAccountName(a.name)}>{a.name}</button>)}
        <br/>
        {accountName.length>0 && <Account name={accountName}/>}
    </div>;
}
