import {Portfolio} from "../Domain";
import React, {useEffect} from "react";
import {Chart} from "react-google-charts";

export const StockPortionPieChart = (props: { portfolio: Portfolio }) => {

    const [chartData, setChartData] = React.useState<any[][]>([["Name", "Amount"]]);

    useEffect(() => {
        const mappedChartData = [
            ["Name", "Amount"],
            ["Cash", props.portfolio.cash],
        ];

        const sortedInvestments = [...props.portfolio.investments];
        sortedInvestments.sort((a, b) => a.numberOfShares * a.price - b.numberOfShares * b.price);

        sortedInvestments.reduce((acc, stock) => {
            mappedChartData.push([stock.name, stock.numberOfShares * stock.price])
            return mappedChartData;
        }, mappedChartData);
        setChartData(mappedChartData);
    }, [props.portfolio.cash, props.portfolio.investments]);

    return (<div className="Widget">
        <Chart
            height={'400px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={{
                backgroundColor: 'black',
                chartArea: {width: '90%', height: '90%'},
                titleTextStyle: {
                    color: 'white',
                },
                legendTextStyle: {
                    color: 'white',
                },
                pieHole: 0.7,
            }}
            rootProps={{'data-testid': '3'}}
        />
    </div>)
};
